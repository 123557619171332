// 埋点接口
import {post} from './request';

/**
 * 
 * @param {*} event 事件类型
 * @param {Object} params 事件上报具体数据
 * @returns 
 */
function sendData(params) {
	return new Promise((resolve, reject) => {
		post(
			'/event/track_event',
			params,
		).then(res => {
			if (res.code === 200) {
				return resolve(res.data)
			}
			return reject(res)
		}).catch(err => {
			return reject(err)
		})
	})
}

export {
	sendData
}
