<template>
  <a-spin size="large" :spinning="loading">
    <common-layout style="padding-top: 150px">
      <div class="top">
        <div class="header">
          <!-- <img alt="logo" class="logo" src="@/assets/img/logo2.png" /> -->
          <svg-icon
            iconClass="login"
            class="logo"
            style="width: 309px; height: 44px; background: none"
          ></svg-icon>
        </div>
      </div>
      <div class="login">
        <div class="title">{{ $t("LogIn") }}</div>
        <div class="subTitle">{{ $t("EntertheFuture") }}</div>
        <div class="auth">
          <ul>
            <li @click="author('google-oauth2')">
              <svg-icon iconClass="google"></svg-icon>
              <span :class="[lang]">{{ $t("SigninwithGoogle") }}</span>
            </li>
            <li @click="author('linkedin')">
              <svg-icon iconClass="linkin"></svg-icon>
              <span :class="[lang]">{{ $t("SigninwithLinkedIn") }}</span>
            </li>
            <li @click="author('facebook')">
              <svg-icon iconClass="facebook"></svg-icon>
              <span :class="[lang]">facebook</span>
            </li>
          </ul>
        </div>
        <a-form
          class="wrap-login"
          @submit="onSubmit"
          :form="form"
          style="display: none"
        >
          <a-form-item>
            <a-input
              autocomplete="autocomplete"
              size="large"
              placeholder="Email address"
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入账户名',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              size="large"
              placeholder="Password"
              autocomplete="autocomplete"
              type="password"
              v-decorator="[
                'password',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入密码',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input>
          </a-form-item>
          <!-- <a-tabs
          size="large"
          :tabBarStyle="{ textAlign: 'center' }"
          style="padding: 0 2px"
        >
          <a-tab-pane tab="账户密码登录" key="1">
            <a-alert
              type="error"
              :closable="true"
              v-if="error"
              :message="error"
              @close="onClose"
              showIcon
              style="margin-bottom: 24px"
            />
            <a-form-item>
              <a-input
                autocomplete="autocomplete"
                size="large"
                placeholder="admin"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入账户名',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                size="large"
                placeholder="888888"
                autocomplete="autocomplete"
                type="password"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入密码',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input>
            </a-form-item>
          </a-tab-pane>
          <a-tab-pane tab="手机号登录" key="2">
            <a-form-item>
              <a-input size="large" placeholder="mobile number">
                <a-icon slot="prefix" type="mobile" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-row :gutter="8" style="margin: 0 -4px">
                <a-col :span="16">
                  <a-input size="large" placeholder="captcha">
                    <a-icon slot="prefix" type="mail" />
                  </a-input>
                </a-col>
                <a-col :span="8" style="padding-left: 4px">
                  <a-button
                    style="width: 100%"
                    class="captcha-button"
                    size="large"
                    >获取验证码</a-button
                  >
                </a-col>
              </a-row>
            </a-form-item>
          </a-tab-pane>
        </a-tabs> -->
          <!-- <div>
          <a-checkbox :checked="true">自动登录</a-checkbox>
          <a style="float: right">忘记密码</a>
        </div> -->
          <!-- <a-form-item>
            <a-button
              :loading="logging"
              style="width: 100%; margin-top: 24px"
              size="large"
              htmlType="submit"
              type="primary"
              >Login</a-button
            >
          </a-form-item>
          <div class="forget">Forgot Password?</div> -->
          <!-- <ul>
          <li @click="author('google-oauth2')">google chrome</li>
          <li @click="author('facebook')">facebook</li>
          <li @click="author('linkedin')">linkedin</li>
          <li @click="reg()">注册</li>

          {{
            user
          }}
        </ul> -->
        </a-form>
      </div>
      <!-- <p class="noHave">Don't have an account?</p>
      <div class="reg">Sign up</div> -->
    </common-layout>
  </a-spin>
</template>

<script>
import CommonLayout from "@/layouts/CommonLayout";
import { login } from "@/services/user";
import { setAuthorization } from "@/utils/request";
// import { loadRoutes } from "@/utils/routerUtil";
import { mapMutations } from "vuex";
// import { useAuth0 } from "@auth0/auth0-vue";
// const auth0 = useAuth0();
import { sendData } from "@/utils/burying-point";
export default {
  name: "Login",
  components: { CommonLayout },
  data() {
    return {
      logging: false,
      isAuthenticated: false,
      isLoading: false,
      error: "",
      loading: false,
      form: this.$form.createForm(this),
      showPage: false,
    };
  },

  computed: {
    systemName() {
      return this.$store.state.setting.systemName;
    },
  },
  i18n: require("./i18n"),
  created() {},
  mounted() {
    // 数据上报
    sendData({
      event_name:'vist_login'
    });
    if (localStorage.getItem("token")) {
      this.$router.push("/sales/salesReps");
      return;
    }
  },
  methods: {
    reg() {
      this.webAuth.signup(
        {
          connection: "Username-Password-Authentication",
          email: "412699784@qq.com",
          password: "123456",
          username: "johndoe",
          given_name: "John",
          family_name: "Doe",
          name: "John Doe",
          nickname: "johnny",
          picture: "http://example.org/jdoe.png",
          user_metadata: { plan: "silver", team_id: "a111" },
        },
        function (err) {
          if (err) return alert("Something went wrong: " + err.message);
          return alert("success signup without login!");
        }
      );
    },
    author(op) {
      this.loading = true;
      let event_name = 'login_button_google'
      switch (op) {
        case 'google-oauth2':
          event_name = 'login_button_google'
          break;
        case 'facebook':
          event_name = 'login_button_facebook'
          break;
        case 'linkedin':
          event_name = 'login_button_linkedin'
          break;
      }
      sendData({
        event_name
      })
      this.webAuth.authorize(
        {
          responseType: "token",
          connection: op,
          // redirectUri: "http://localhost:8080/loginskip",
          redirectUri: process.env.VUE_APP_SKIP_BASE_URL,
          // authorizationParams: {
          //   redirect_uri: "http://localhost:8080/login",
          // },
        },
        (err, authResult) => {
          console.log(err, authResult);
          this.loading = false;
          //do something
        }
      );
    },
    ...mapMutations("account", ["setUser", "setPermissions", "setRoles"]),
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true;
          const name = this.form.getFieldValue("name");
          const password = this.form.getFieldValue("password");
          login(name, password).then(this.afterLogin);
        }
      });
    },
    afterLogin(res) {
      this.logging = false;
      const loginRes = res;
      if (loginRes.code >= 0) {
        const { user, permissions, roles } = loginRes.data;
        this.setUser(user);
        this.setPermissions(permissions);
        this.setRoles(roles);
        setAuthorization({
          token: loginRes.data.token,
          expireAt: new Date(loginRes.data.expireAt),
        });
        this.$router.push("/sales/salesReps");
        this.$message.success(loginRes.message, 3);
        // 获取路由配置
        // getRoutesConfig().then((result) => {
        //   ;
        //   const routesConfig = result.data.data;
        //   console.log("routesConfig", routesConfig);
        //   alert(3);
        //   ;
        //   loadRoutes(routesConfig);
        //   alert(333);
        //   ;
        //   //this.$router.push("/sales/salesReps");
        //   this.$message.success(loginRes.message, 3);
        // });
      } else {
        this.error = loginRes.message;
      }
    },
    onClose() {
      this.error = false;
    },
  },
};
</script>

<style lang="less" scoped>
.common-layout {
  .top {
    text-align: center;
    .header {
      height: 68px;
      line-height: 44px;
      a {
        text-decoration: none;
      }
      .logo {
        width: auto;
        height: 44px;
        vertical-align: top;
      }
      .title {
        font-size: 33px;
        color: @title-color;
        font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
          sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
      .subTitle {
      }
    }
    .desc {
      font-size: 14px;
      color: @text-color-second;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  .login {
    .title {
      font-family: Arial, Arial;
      font-weight: bold;
      font-size: 28px;
      color: #000000;
      margin-left: 68px;
    }
    .subTitle {
      margin-top: 15px;
      margin-left: 68px;
      font-family: Arial, Arial;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }
    .auth {
      ul,
      li {
        padding: 0;
        margin: 0;
        list-style: none;
      }
      ul {
        width: 360px;
        margin: 0 auto;
      }
      li {
        cursor: pointer;
        margin-top: 23px;
        width: 360px;
        height: 40px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #d9d9d9;
        line-height: 40px;
        text-align: center;
      }
    }
    .forget {
      font-family: Arial, Arial;
      font-weight: 400;
      font-size: 12px;
      color: #0e756a;
      cursor: pointer;
      margin-top: 30px;
      text-align: center;
    }
    width: 496px;

    background: #ffffff;
    box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 21px 21px 21px 21px;
    margin: 0 auto;
    padding: 30px 0;

    @media screen and (max-width: 576px) {
      padding: 60px 0;
    }
    // @media screen and (max-width: 320px) {
    //   .captcha-button {
    //     font-size: 14px;
    //   }
    // }
    .wrap-login {
      width: 360px;
      margin: 0 auto;
      margin-top: 42px;
    }
    .icon {
      font-size: 24px;
      color: @text-color-second;
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }
  }
  .noHave {
    font-family: Arial, Arial;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin-top: 34px;
  }
  .forget,
  .reg {
    color: #0e756a;
    text-decoration: underline;
    text-align: center;
  }
}
</style>
