var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { size: "large", spinning: _vm.loading } },
    [
      _c("common-layout", { staticStyle: { "padding-top": "150px" } }, [
        _c("div", { staticClass: "top" }, [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("svg-icon", {
                staticClass: "logo",
                staticStyle: {
                  width: "309px",
                  height: "44px",
                  background: "none"
                },
                attrs: { iconClass: "login" }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "login" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("LogIn")))
            ]),
            _c("div", { staticClass: "subTitle" }, [
              _vm._v(_vm._s(_vm.$t("EntertheFuture")))
            ]),
            _c("div", { staticClass: "auth" }, [
              _c("ul", [
                _c(
                  "li",
                  {
                    on: {
                      click: function($event) {
                        return _vm.author("google-oauth2")
                      }
                    }
                  },
                  [
                    _c("svg-icon", { attrs: { iconClass: "google" } }),
                    _c("span", { class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("SigninwithGoogle")))
                    ])
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    on: {
                      click: function($event) {
                        return _vm.author("linkedin")
                      }
                    }
                  },
                  [
                    _c("svg-icon", { attrs: { iconClass: "linkin" } }),
                    _c("span", { class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("SigninwithLinkedIn")))
                    ])
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    on: {
                      click: function($event) {
                        return _vm.author("facebook")
                      }
                    }
                  },
                  [
                    _c("svg-icon", { attrs: { iconClass: "facebook" } }),
                    _c("span", { class: [_vm.lang] }, [_vm._v("facebook")])
                  ],
                  1
                )
              ])
            ]),
            _c(
              "a-form",
              {
                staticClass: "wrap-login",
                staticStyle: { display: "none" },
                attrs: { form: _vm.form },
                on: { submit: _vm.onSubmit }
              },
              [
                _c(
                  "a-form-item",
                  [
                    _c(
                      "a-input",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "name",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入账户名",
                                    whitespace: true
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入账户名',\n                    whitespace: true,\n                  },\n                ],\n              },\n            ]"
                          }
                        ],
                        attrs: {
                          autocomplete: "autocomplete",
                          size: "large",
                          placeholder: "Email address"
                        }
                      },
                      [
                        _c("a-icon", {
                          attrs: { slot: "prefix", type: "user" },
                          slot: "prefix"
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  [
                    _c(
                      "a-input",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "password",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入密码",
                                    whitespace: true
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入密码',\n                    whitespace: true,\n                  },\n                ],\n              },\n            ]"
                          }
                        ],
                        attrs: {
                          size: "large",
                          placeholder: "Password",
                          autocomplete: "autocomplete",
                          type: "password"
                        }
                      },
                      [
                        _c("a-icon", {
                          attrs: { slot: "prefix", type: "lock" },
                          slot: "prefix"
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }