module.exports = {
  messages: {
    CN: {
      LogIn: "登录",
      SigninwithGoogle: "谷歌登录",
      SigninwithLinkedIn: "领英登录",
      EntertheFuture: "进入未来最智能的领英自动化工具",
      Dataisloading: "数据正在加载中",
    },

    US: {
      LogIn: "Log In",
      SigninwithGoogle: "Sign in with Google",
      SigninwithLinkedIn: "Sign in with LinkedIn",
      EntertheFuture: "Please log in directly to test all features for free without needing to link a credit card.",
      Dataisloading: "Data is loading",
    },
    AR: {
      LogIn: "تسجيل الدخول",
      SigninwithGoogle: "تسجيل الدخول بواسطة جوجل",
      SigninwithLinkedIn: "تسجيل الدخول بواسطة لينكد إن",
      EntertheFuture: "ادخل إلى مستقبل أتمتة لينكد إن الذكية",
      Dataisloading: "يتم تحميل البيانات",
    },
  },
};
